var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _vm.$store.state.Ticket.isEdit &&
      (_vm.$store.state.Ticket.isEdit == "UpdateTicket" ||
        _vm.$store.state.Ticket.isEdit == "DestroyedMarks" ||
        _vm.$store.state.Ticket.isEdit == "RetransmitTicket" ||
        _vm.$store.state.Ticket.isEdit == "Digin")
        ? _c(
            "v-row",
            {
              staticClass: "blue lighten-5",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "span",
                { staticClass: "text--lighten-5 font-weight-medium my-2" },
                [_vm._v(" " + _vm._s(_vm.$t("updateMsg")))]
              ),
            ]
          )
        : _vm._e(),
      _vm.$store.state.Ticket.isEdit &&
      _vm.$store.state.Ticket.isEdit == "createTicket"
        ? _c(
            "v-row",
            {
              staticClass: "blue lighten-5",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "span",
                { staticClass: "text--lighten-5 font-weight-medium my-2" },
                [_vm._v(" " + _vm._s(_vm.$t("upadteCannotCompleted")))]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "text-h5 font-weight-medium mt-2",
              class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8" : "pl-5",
              staticStyle: { "white-space": "nowrap" },
              attrs: {
                cols: _vm.$vuetify.breakpoint.mdAndUp ? "2" : "8",
                md: "2",
                lg: "2",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("createTicket")) + " ")]
          ),
          _c(
            "v-col",
            { staticClass: "py-0 my-0" },
            [
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c("TicketProgressBar", {
                    attrs: { "current-step": _vm.currentStep },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mr-3" },
            [
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-col",
                    { staticClass: "ml-8" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "font-weight-regular text-none rounded-lg mt-1",
                          attrs: { outlined: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = true
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
      _c(
        "v-row",
        [
          !_vm.$vuetify.breakpoint.mdAndUp
            ? _c("MobileProgress", {
                attrs: { "current-step": _vm.currentStep },
                on: {
                  cancelTicket: function ($event) {
                    _vm.dialog = true
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c("v-row", { staticClass: "mt-6" }, [_c("v-divider")], 1)
        : _vm._e(),
      _c(
        "v-row",
        { class: _vm.$vuetify.breakpoint.mdAndUp ? null : "mt-6" },
        [
          _vm.currentStep === 1
            ? _c("ExcavationLocation", {
                attrs: { "is-edit-view": _vm.isEditView },
                on: { locationFound: _vm.locationFound },
              })
            : _vm._e(),
          _vm.currentStep === 2
            ? _c("ExcavationDetails", {
                attrs: {
                  "map-view": _vm.mapView,
                  "is-edit-view": _vm.isEditView,
                },
                on: {
                  excavationDetailsCompleted: _vm.excavationDetailsCompleted,
                  excavationDetailsBack: _vm.excavationDetailsBack,
                  editLocation: _vm.editLocation,
                },
              })
            : _vm._e(),
          _vm.currentStep === 3
            ? _c("ReviewTicket", {
                attrs: { "map-view": _vm.mapView },
                on: {
                  reviewTicketBack: _vm.onReviewTicketBack,
                  editLocation: _vm.editLocation,
                  submitTicket: _vm.submitTicket,
                  scheduleTicket: _vm.scheduleTicket,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("CancelDialog", {
        attrs: { dialog: _vm.dialog },
        on: { discardTicket: _vm.discardTicket, closeDialog: _vm.closeDialog },
      }),
      _vm.isTicketSubmitted
        ? _c("TicketSubmitMessage", {
            attrs: {
              dialog: _vm.isTicketSubmitted,
              "ticket-number": _vm.ticketNumber,
              "ticket-submission-message": _vm.ticketSubmissionMessage,
              "show-suspend-msg": _vm.showSuspendMsg,
            },
            on: { onCloseDialog: _vm.onCloseTicketAcknowledgement },
          })
        : _vm._e(),
      _vm.isError
        ? _c("ErrorMessage", {
            attrs: {
              "error-code": _vm.errorCode,
              "error-message": _vm.errorMessage,
            },
            on: {
              ticketScreen: function ($event) {
                _vm.isError = false
              },
            },
          })
        : _vm._e(),
      _vm.showDialog
        ? _c("TicketCustomMessage", {
            attrs: {
              "header-message": _vm.dialogData.headerMessage,
              "message-icon": _vm.dialogData.messageIcon,
              text: _vm.dialogData.text,
              dialog: true,
            },
            on: { onCloseDialog: _vm.onCloseDialog },
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "420", "max-height": "400", persistent: "" },
          model: {
            value: _vm.timeLimitDialog,
            callback: function ($$v) {
              _vm.timeLimitDialog = $$v
            },
            expression: "timeLimitDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0 ml-4 mb-10 pt-4",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "grey--text text--darken-4 text-subtitle-1 font-weight-regular",
                    },
                    [_vm._v("Ticket creation time limit exceed ")]
                  ),
                ]
              ),
              _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "my-1", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "primary text-none rounded-lg elevation-0",
                          attrs: { width: "90", depressed: "" },
                          on: { click: _vm.discardTicket },
                        },
                        [_vm._v(" oK ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }