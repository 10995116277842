<template>
  <v-container fluid pa-0>
    <v-row
      align="center"
      justify="center"
      class="blue lighten-5"
      v-if="
        $store.state.Ticket.isEdit &&
        ($store.state.Ticket.isEdit == 'UpdateTicket' ||
          $store.state.Ticket.isEdit == 'DestroyedMarks' ||
          $store.state.Ticket.isEdit == 'RetransmitTicket' ||
          $store.state.Ticket.isEdit == 'Digin')
      "
    >
      <span class="text--lighten-5 font-weight-medium my-2">
        {{ $t("updateMsg") }}</span
      >
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="blue lighten-5"
      v-if="
        $store.state.Ticket.isEdit &&
        $store.state.Ticket.isEdit == 'createTicket'
      "
    >
      <span class="text--lighten-5 font-weight-medium my-2">
        {{ $t("upadteCannotCompleted") }}</span
      >
    </v-row>
    <v-row>
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '2' : '8'"
        md="2"
        lg="2"
        style="white-space: nowrap"
        class="text-h5 font-weight-medium mt-2"
        :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
      >
        {{ $t("createTicket") }}
      </v-col>
      <v-col class="py-0 my-0">
        <TicketProgressBar
          v-if="$vuetify.breakpoint.mdAndUp"
          :current-step="currentStep"
        />
      </v-col>
      <div class="mr-3">
        <v-col v-if="$vuetify.breakpoint.mdAndUp" class="ml-8">
          <v-btn
            outlined
            color="primary"
            class="font-weight-regular text-none rounded-lg mt-1"
            @click="dialog = true"
          >
            {{ $t("cancel") }}</v-btn
          >
        </v-col>
      </div>
    </v-row>
    <v-row class="mb-2">
      <v-divider></v-divider>
    </v-row>
    <v-row>
      <MobileProgress
        v-if="!$vuetify.breakpoint.mdAndUp"
        :current-step="currentStep"
        @cancelTicket="dialog = true"
      />
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown" class="mt-6">
      <v-divider></v-divider>
    </v-row>
    <v-row :class="$vuetify.breakpoint.mdAndUp ? null : 'mt-6'">
      <ExcavationLocation
        v-if="currentStep === 1"
        :is-edit-view="isEditView"
        @locationFound="locationFound"
      />
      <ExcavationDetails
        v-if="currentStep === 2"
        :map-view="mapView"
        :is-edit-view="isEditView"
        @excavationDetailsCompleted="excavationDetailsCompleted"
        @excavationDetailsBack="excavationDetailsBack"
        @editLocation="editLocation"
      />
      <ReviewTicket
        v-if="currentStep === 3"
        :map-view="mapView"
        @reviewTicketBack="onReviewTicketBack"
        @editLocation="editLocation"
        @submitTicket="submitTicket"
        @scheduleTicket="scheduleTicket"
      />
    </v-row>
    <CancelDialog
      :dialog="dialog"
      @discardTicket="discardTicket"
      @closeDialog="closeDialog"
    />
    <TicketSubmitMessage
      v-if="isTicketSubmitted"
      :dialog="isTicketSubmitted"
      :ticket-number="ticketNumber"
      :ticket-submission-message="ticketSubmissionMessage"
      :show-suspend-msg="showSuspendMsg"
      @onCloseDialog="onCloseTicketAcknowledgement"
    />
    <ErrorMessage
      v-if="isError"
      :error-code="errorCode"
      :error-message="errorMessage"
      @ticketScreen="isError = false"
    />
    <TicketCustomMessage
      v-if="showDialog"
      :header-message="dialogData.headerMessage"
      :message-icon="dialogData.messageIcon"
      :text="dialogData.text"
      :dialog="true"
      @onCloseDialog="onCloseDialog"
    />
    <v-dialog
      v-model="timeLimitDialog"
      max-width="420"
      max-height="400"
      persistent
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4 mb-10 pt-4" justify="center">
          <span
            class="grey--text text--darken-4 text-subtitle-1 font-weight-regular"
            >Ticket creation time limit exceed
          </span>
        </v-row>
        <v-row class="mb-2">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions>
          <v-row justify="center" class="my-1">
            <v-btn
              class="primary text-none rounded-lg elevation-0"
              width="90"
              depressed
              @click="discardTicket"
            >
              oK
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getTicketData } from "@/js/helper.js";
import moment from "moment";
import UserDataMixin from "@/mixins/UserData";

export default {
  name: "CreateTicket",
  components: {
    TicketProgressBar: () => import("./ticket-progressbar.vue"),
    MobileProgress: () => import("./mobile-progress.vue"),
    CancelDialog: () => import("./cancel-dialog.vue"),
    ExcavationDetails: () => import("./excavation-details.vue"),
    ReviewTicket: () => import("./ReviewTicket.vue"),
    TicketSubmitMessage: () => import("./TicketSubmitMessage.vue"),
    ErrorMessage: () => import("../../molecules/ErrorMessage.vue"),
    TicketCustomMessage: () =>
      import("../../molecules/ticketCustomMessage.vue"),
    ExcavationLocation: () => import("./ExcavationLocation.vue"),
  },
  props: {},
  mixins: [UserDataMixin],
  data() {
    return {
      ticketTimer: "",
      bottomNavbar: true,
      currentStep: this.$store.state.Ticket.currentStep,
      isAddressFound: false,
      geoJson: {},
      dialog: false,
      geoAddress: "",
      selectedPlace: "",
      mapView: "",
      isEditView: false,
      isTicketSubmitted: false,
      ticketNumber: "",
      ticketSubmissionMessage: "",
      errorCode: "",
      errorMessage: "",
      isError: false,
      showDialog: false,
      customDialog: true,
      dialogData: { text: "", headerMessage: "", messageIcon: "" },
      showSuspendMsg: false,
      timeLimitDialog: false,
    };
  },
  watch: {
    currentStep() {
      const { center } = this.$store.state.Ticket.ticketData;
      if (this.currentStep === 2 && !this.ticketTimer && center === "OUPS") {
        this.ticketTimer = setTimeout(() => {
          this.timeLimitDialog = true;
          this.ticketTimer = "";
        }, 3600000);
      } else if (this.currentStep == 1) {
        clearTimeout(this.ticketTimer);
        this.ticketTimer = "";
      }
    },
  },
  async created() {
    if (!this.$store.state.Ticket.isEdit)
      this.$store.commit("resetTicketState");
    if (!this.featureEnabled("createTickets"))
      this.$router.push("/disableAccess").catch();
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async discardTicket() {
      this.currentStep = 0;
      this.$store.commit("setIsEdit", "");
      this.dialog = false;
      await this.$store.commit("resetTicketState");
      if (this.$store.state.App.lastRoute) {
        this.$router.push(this.$store.state.App.lastRoute).catch();
        this.$store.commit("SET_LAST_ROUTE", null);
      }
      this.currentStep = 1;
      if (this.timeLimitDialog) this.timeLimitDialog = false;
    },
    locationFound(geoJson, geoAddress, place, mapView, isEditView) {
      if (geoJson.features && geoJson.features.length > 1)
        this.isAddressFound = true;
      else this.isAddressFound = false;
      this.geoJson = geoJson;
      this.geoAddress = geoAddress;
      this.selectedPlace = place;
      this.mapView = mapView;
      this.currentStep = 2;
      this.isEditView = isEditView;
    },
    excavationDetailsCompleted() {
      if (this.$store.state.Ticket.isEdit !== "") {
        var canvas = document.getElementById("cvsMap");
        var img = document
          .querySelector("#map")
          .querySelector("canvas")
          .toDataURL("image/png");
        this.mapView = img;
      }
      this.currentStep = 3;
    },
    excavationDetailsBack() {
      this.currentStep = 1;
    },
    onReviewTicketBack() {
      this.currentStep = 2;
    },
    editLocation() {
      this.currentStep = 1;
    },
    onClickRetry() {
      this.geoJson = {};
      this.isAddressFound = false;
    },
    async approval(apiData) {
      let ticketResp;
      if (this.$store.state.Ticket.isEdit === "approvalTicket") {
        apiData.approval_id =
          this.$store.state.Approval.approvalDetails.approval_id;
        apiData.status = "pending";
        ticketResp = await this.$store.dispatch("updateApproval", apiData);
      } else ticketResp = await this.$store.dispatch("addApproval", apiData);
      if (ticketResp.status === "error") {
        this.errorCode = ticketResp.errorCode.toString();
        this.errorMessage = ticketResp.message;
        this.isError = true;
        return false;
      }
      return true;
    },
    async submitTicket() {
      const user = this.$store.state.User.user;
      const company = this.$store.state.Company.company;
      const ticketDetails = this.$store.state.Ticket.ticketData;
      const excavationDetails = this.$store.state.Ticket.excavationDetails;
      const features = this.$store.state.Ticket.geoJson.features;
      if (user.approver_id) {
        const userData = await this.$store.dispatch("getUserDataById", {
          userId: user.approver_id,
        });
        if (userData.status !== "error") {
          let apiData = {
            options: {
              ticketData: {
                state: ticketDetails.state,
                county: ticketDetails.county,
                street: ticketDetails.street,
                place: ticketDetails.place,
                autoSuggestAddress: ticketDetails.autoSuggestAddress,
                center: ticketDetails.center,
                callerType: ticketDetails.callerType,
                center_id: ticketDetails.center_id,
                parcelExactInPlace: ticketDetails.parcelExactInPlace,
                streetSearchResults: ticketDetails.streetSearchResults,
              },
              excavationDetails: excavationDetails,
              geoJson: this.$store.state.Ticket.geoJson,
            },
            approver_user_id: user.approver_id,
            sender_user_id: user.user_id,
            company_id: company.company_id,
            type: "NEW",
            center_id: ticketDetails.center_id,
            status: "pending",
            comments: {},
          };
          const approvalResp = await this.approval(apiData);
          if (approvalResp) {
            this.dialogData.text = `This ticket required approval from ${userData[0].name}.`;
            this.dialogData.headerMessage = "Approval Required";
            this.dialogData.messageIcon = "approval";
            this.showDialog = true;
          }
        }
        this.$store.commit("setIsEdit", "");
        return;
      }

      const apiData = getTicketData(
        ticketDetails,
        excavationDetails,
        features,
        user,
        company
      );
      if (excavationDetails.meansOfExcavationOptions)
        delete excavationDetails.meansOfExcavationOptions;
      if (excavationDetails.workTypeOptions)
        delete excavationDetails.workTypeOptions;
      if (ticketDetails.states) delete ticketDetails.states;
      const payload = {
        excavationDetails: excavationDetails,
        geoJson: this.$store.state.Ticket.geoJson,
        ticketData: ticketDetails,
        features,
      };

      // Check if the center forces suspend
      let postRoutine =
        this.$store.state.Ticket.isEdit &&
        (this.$store.state.Ticket.isEdit == "UpdateTicket" ||
          this.$store.state.Ticket.isEdit == "DestroyedMarks" ||
          this.$store.state.Ticket.isEdit == "Digin")
          ? "updateExistingTicket"
          : this.forceSuspend(apiData.center_id)
          ? "suspendTicket"
          : "createTicket";

      if (
        this.$store.state.Ticket.ticketData.state == "IL" &&
        this.$store.state.Ticket.isEdit &&
        this.$store.state.Ticket.isEdit === "RetransmitTicket"
      ) {
        postRoutine = "updateExistingTicket";
      }
      if (postRoutine === "updateExistingTicket") {
        payload.apiData = apiData;
      }
      // Dispatch the action to the store
      const ticketResp = await this.$store.dispatch(postRoutine, payload);
      if (ticketResp.status !== "error") {
        this.isTicketSubmitted = true;
        this.ticketNumber = `${ticketResp[0].center_ticket_id} - ${ticketResp[0].revision}`;
        const { center } = this.$store.state.Ticket.ticketData;
        const { priority } = ticketResp[0];

        this.ticketSubmissionMessage =
          center === "OUPS" && priority === "INSF"
            ? "Any date and time can be requested but the utilities will not assure markings without the 48 hours notice."
            : center === "NCOCC" && priority === "SHRT"
            ? "Please note that you have submitted a short notice ticket. NC state law requires a 3 working day notice be given prior to excavation to have the facilities located."
            : "";
        if (postRoutine === "suspendTicket") this.showSuspendMsg = true;
        else this.showSuspendMsg = false;
      } else {
        this.errorCode = ticketResp.errorCode.toString();
        this.errorMessage = ticketResp.error;
        this.isError = true;
      }
    },
    async postScheduleTicket(payload) {
      const ticketResp = await this.$store.dispatch("scheduleTicket", payload);
      if (ticketResp.status === "error") {
        this.errorCode = ticketResp.errorCode.toString();
        this.errorMessage = ticketResp.message;
        this.isError = true;
        return false;
      }
      return true;
    },
    async updateScheduleTicket(payload) {
      const ticketResp = await this.$store.dispatch("updateScheduleTicket", {
        payload: payload,
        scheduled_ticket_id:
          this.$store.state.Ticket.scheduleDetails.scheduled_ticket_id,
      });
      if (ticketResp.status === "error") {
        this.errorCode = ticketResp.errorCode.toString();
        this.errorMessage = ticketResp.message;
        this.isError = true;
        return false;
      }
      return true;
    },
    async scheduleTicket(scheduleObject) {
      const user = this.$store.state.User.user;
      const company = this.$store.state.Company.company;
      const ticketDetails = this.$store.state.Ticket.ticketData;
      const excavationDetails = this.$store.state.Ticket.excavationDetails;
      const features = this.$store.state.Ticket.geoJson.features;
      const payload = getTicketData(
        ticketDetails,
        excavationDetails,
        features,
        user,
        company
      );
      const date = this.formatDate(scheduleObject.schedule_date);
      const time = scheduleObject.schedule_time;

      const dateTime = moment(
        `${date} ${time}`,
        "YYYY-MM-DD HH:mm:ss"
      ).format();
      const localDate = new Date(dateTime);
      let scheduleDateTime = moment.utc(localDate).format();
      let apiData = {
        options: {
          ticketData: {
            state: ticketDetails.state,
            county: ticketDetails.county,
            street: ticketDetails.street,
            place: ticketDetails.place,
            autoSuggestAddress: ticketDetails.autoSuggestAddress,
            center: ticketDetails.center,
            callerType: ticketDetails.callerType,
            center_id: ticketDetails.center_id,
            parcelExactInPlace: ticketDetails.parcelExactInPlace,
            streetSearchResults: ticketDetails.streetSearchResults,
          },
          excavationDetails: excavationDetails,
          geoJson: this.$store.state.Ticket.geoJson,
          scheduleDetails: scheduleObject,
          payload: {
            data: payload.data,
          },
        },
        user_id: user.user_id,
        company_id: company.company_id,
        type: "NEW",
        center_id: ticketDetails.center_id,
        status: "pending",
        error: {},
        center:
          this.$store.state.User.centerMap[ticketDetails.center_id].center_name,
        scheduled_date: scheduleDateTime,
      };

      let scheduleTicketApiResp;
      if (
        this.$store.state.Ticket.isEdit &&
        this.$store.state.Ticket.scheduleDetails.scheduled_ticket_id
      ) {
        scheduleTicketApiResp = await this.updateScheduleTicket(apiData);
      } else {
        scheduleTicketApiResp = await this.postScheduleTicket(apiData);
      }
      if (scheduleTicketApiResp) {
        this.dialogData.text = `Your ticket is scheduled and will be submitted to 811 center on ${scheduleObject.schedule_date}.`;
        this.dialogData.headerMessage = "Ticket Scheduled";
        this.dialogData.messageIcon = "schedule";
        this.showDialog = true;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    async onCloseTicketAcknowledgement() {
      this.isTicketSubmitted = false;
      if (this.checkPermission("viewTickets")) {
        this.$router.push("/tickets").catch();
      } else {
        this.$store.commit("setIsEdit", "");
        await this.$store.commit("resetTicketState");
        this.currentStep = 1;
        this.ticketNumber = "";
      }
    },
    async onCloseDialog() {
      this.showDialog = false;
      this.$router.push("/tickets").catch();
    },
    forceSuspend(centerId) {
      let allCenters = this.$store.state.Company.company.centers;
      let center = allCenters.find((center) => center.center_id === centerId);
      return center.options.newtin2?.suspend_converts?.length > 0;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
